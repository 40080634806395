import React, {useState, useEffect} from 'react';
import {Col, Row, Toast, ToastBody, Button} from 'reactstrap';
import { NavLink } from 'react-router-dom';

const DynamicToast = () => {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const dismissed = localStorage.getItem('bannerDismissed');
        if (dismissed) {
            setVisible(false);
        }
    }, []);

    const dismiss = () => {
        setVisible(false);
        localStorage.setItem('bannerDismissed', 'true');
    };

    return (
        <>
            {visible && (
                <Toast style={{
                    bottom: "1rem",
                    width: "calc(100% - 2rem)",
                    right: "1rem",
                    maxWidth: "430px",
                    zIndex: 20
                }} className="position-fixed text-bg-warning text-primary">
                    <ToastBody>
                        <Row>
                            <Col xs={12}>
                                <p className="mb-3">Ab sofort können alle Mobilfunknummern <strong>erneut einen SMS-Code anfordern und abstimmen</strong></p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6}>
                                <Button className="w-100" tag={NavLink} to="/code-anfordern" size="sm" color="primary">Jetzt Code anfordern</Button>
                            </Col>
                            <Col xs={6}>
                                <Button className="w-100" size="sm" color="primary" outline onClick={dismiss}>Schließen</Button>
                            </Col>
                        </Row>
                    </ToastBody>

                </Toast>
            )}
        </>
    );
};

export default DynamicToast;
